<template>
	<div>
		<Header ref="Header" />
		<img :src="menuPic" class="Big-title" />
		<div class="container">
			<div class="topic-list">
				<div v-for="(item,index) in list" :key="index">
					<div class="topic-list-item row">
						<img @click="topicJump(item)" class="topic-list-pic jump"
							:src="item.picture" />
						<div class="topic-list-right">
							<div class="topic-list-up">
								<div @click="topicJump(item)" class="topic-list-title jump">{{item.title}}</div>
								<div @click="topicJump(item)" class="topic-list-text jump">{{item.remark}}</div>
							</div>
							<div class="topic-list-line flex">
								<div class="topic-list-time">{{item.createTime}}</div>
								<div class="topic-item-right flex">
									<div class="topic-list-view">浏览 {{item.viewCount}}</div>
									<div @click="topicJump(item)" class="topic-list-btn jump">查看详情</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bar-box">
				<el-pagination
					class="bar-switch"
					background
					layout="prev, pager, next, jumper, total"
					:page-size="searchDate.pageSize"
					:total="Total"
					@current-change="choosePage">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue';
	export default {
		components: {
			Header
		},
		data() {
			return {
				menuPic:'',
				bar: 1,
				searchDate:{
					pageNum:1,
					pageSize:10,
				},
				Total:0,
				list:[],
			}
		},
		mounted() {
			this.$refs.Header.setIndex(2);
			// 获取菜单图片
			this.$api.getMenuPic('专题推荐').then(res=>{
				console.log(res,'菜单图片');
				this.menuPic=res.data[0].picture;
			})
			//获取专题推荐列表
			this.getTopicList()
		},
		methods: {
			pageTo(id) {
				this.$router.push({
					path: 'passage',
					query: {
						index:2,
						id:id,
					}
				});
			},
			// 选择分页
			choosePage(num){
				this.searchDate.pageNum=num;
				this.getTopicList();
			},
			//获取专题推荐列表
			getTopicList(){
				this.$api.getTopicList(this.searchDate).then(res=>{
					console.log(res,'专题推荐列表');
					this.list=res.rows;
					this.Total=parseInt(res.total);
					// this.pageTotal=Math.ceil(res.total / this.searchDate.pageSize);
				});
			},
			// 专题详情
			topicJump(item){
				if(item.linkType==1){
					this.$router.push({
						path: 'topicDetail',
						query: {
							index:2,
							id:item.id,
						},
					});
				}else if(item.linkType==2){
					window.open(item.link, '_blank');
				}else{
					return
				}
			},
		},
	};
</script>

<style scoped>
</style>
